import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {navigate} from "gatsby";
import {DottedLine} from "../components/line";
import {CartContext} from '../cart';
import {Product} from "../models/product";
import {motion, useAnimation, useSpring} from "framer-motion";
import {useIsMobile} from "../utils";
import {useInView} from "react-intersection-observer";
import { toast } from 'react-toastify';
import queryString from "query-string";
import beanFilterMap from "../beanFilterMap.js";
import { SEO } from '../components/seo';


const SideBarSegment = ({category, value}) => {

    const spring = useSpring(0, {damping: 300, stiffness: 1000});

    useLayoutEffect(() => {
        spring.onChange(latest => {
            window.scrollTo(0, latest);
        });
    }, [spring]);

    function moveTo(to) {
        spring.set(window.pageYOffset, false);
        return new Promise((resolve) => {
            setTimeout(() => {
                spring.set(to);
                resolve(null);
            }, 50);
        });
    }

    return (
        <div style={{
            paddingRight: `3rem`,
            margin: '24px 0',
            cursor: 'pointer',
        }}
             onClick={
            () => {
               // setFold(!fold);
            }}
        >
            <div style={{fontWeight: 'bold'}}>
                {category}
            </div>
            <DottedLine />
            {
                !fold && value.map((e) =>
                    <motion.div
                        //                      exit="exit"
                        //                     initial="initial"
                        //                    animate="animate"
                        style={{
                            paddingLeft: `2rem`,
                            cursor: 'pointer',
                            margin: '16px 0',
                        }
                        }
                        key={`submenu-${e}`}
                        variants={{
                            // initial: {
                            //     opacity: 0,
                            //     y: -20,
                            // },
                            // animate: {
                            //     opacity: 1,
                            //     y: 0,
                            // },
                            // exit: {
                            //     opacity: 0,
                            //     y: -20,
                            // }
                        }}
                        onClick={async () => {
                            await  moveTo(0);
                            return navigate(`/tag/${category}-${e}`);
                        }}
                    >
                        {e}
                    </motion.div>
                )
            }
        </div>
    );
}

const SideBar = ({categories, tags}) => {
    const [curFilter, setCurFilter] = useState(null);
    return (
        <div style={{
            minWidth: 250,
            maxWidth: 250,
            padding: `2rem 0px  0px 4rem`,
        }}>
            <div style={{
                paddingRight: `3rem`,
                cursor: `pointer`,
            }}
                 onClick={() => navigate('/shop')}
            >
                <div className="body2">
                    全部商品
                </div>
            </div>
            <div style={{
                paddingRight: `3rem`,
                cursor: `pointer`,
                marginTop: '24px',
            }}
            >
                <div className="body2">
                  咖啡豆
                </div>
                {
                  ['產區', '工具', '風味', '烘焙'].map((v) => 
                  <div
                    className="body2"
                    style={{ 
                      marginLeft: '24px', 
                      marginTop: '16px',
                    }}
                    onClick={() => { setCurFilter(v); }}
                  >
                    <div
                      className="shopFilterItem body1"
                      style={{
                        width: 'fit-content',
                        ...v === curFilter && {
                          borderBottom: '1px solid black',
                        }
                      }}
                    >
                      {`${v}搜尋`}
                    </div>
                    {
                      v === curFilter &&
                        <div style={{ marginLeft: 24 }}>
                          {
                          tags.filter((tag) => {
                            if (v === '產區') {
                                return tag.includes('產地-');
                            } else if (v === '烘焙') {
                                return tag.includes('焙度-');
                            }
                            return tag.includes(v);
                          })
                          .map((s) => <div 
                            className="shopFilterItem body1"
                            style={{ 
                              marginTop: 16, 
                              width: 'fit-content',
                              ...decodeURI(window.location.search).includes(`=${s}`) && {
                                borderBottom: '1px solid black',
                              }
                            }}
                            onClick={() => navigate(`/shop?search=${s.split('-')[1]}`)}
                          >
                            {s.split('-')[1]}
                          </div>)}
                        </div>
                    }
                  </div>)
                }
            </div>
            <div style={{
                paddingRight: `3rem`,
                cursor: `pointer`,
                marginTop: '24px',
            }}
                 onClick={() => navigate('/shop?type=掛耳式咖啡')}
            >
                <div className="body2">
                  掛耳咖啡
                </div>
            </div>
            <div style={{
                paddingRight: `3rem`,
                cursor: `pointer`,
                marginTop: '24px',
            }}
                 onClick={() => navigate('/shop?type=咖啡禮盒')}
            >
                <div className="body2">
                  咖啡禮盒
                </div>
            </div>
            <div style={{
                paddingRight: `3rem`,
                cursor: `pointer`,
                marginTop: '24px',
            }}
                 onClick={() => navigate('/shop?type=週邊商品')}
            >
                <div className="body2">
                  週邊商品
                </div>
            </div>
        </div>);
}

const ProductItem = ({ product, addItem }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const { isMobile } = useIsMobile();
    const variants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1,
                ease: "easeInOut",
            },
        },
        hidden: {
            opacity: 0,
            y: 70,
            transition: {
                duration: 0.6,
                ease: "easeInOut",
            },
        },
    };
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);
    const motionProps = {
        animate: controls,
        initial: 'hidden',
        //exit: 'hidden',
        variants,
        ref,
    }
  const productVariants = product.variants;
  productVariants.sort((a, b) => {
    return a.price - b.price;
  });
  const mainVariant = productVariants[0];
    return (
        <motion.div
            {...motionProps}
            key={product.id}
            style={{
            display: "flex",
            flexDirection: "column",
            width: isMobile ? 'calc(50% - 32px)' : 250,
            margin: 16,
                alignItems: "center",
                cursor: "pointer",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                    width: '100%',
                }}
                onClick={() => {
                    navigate(`/products/${product.id}?type=${product.productType}`);
                }}
            >
                <img src={product.thumbnailUrl} style={{
                    width: isMobile ? 150 : 250,
                    height: isMobile ? 150 : 250,
                    objectFit: 'cover',
                    aspectRatio: '1 / 1'
                }}/>
                <div className="body2" style={{
                    marginTop: 8,
                    textAlign: 'center',
                    ...isMobile  && {
                        fontSize: 14,
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }
                }}>{product.name}</div>
                {product.engName && <div className="body1" style={{
                  textAlign: 'center',
                    ...isMobile  && {
                        fontSize: 12,
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }
                }}>{product.engName}</div>}
                {/*<p>{product.short_description}</p>*/}
                {
                  mainVariant && (
                    <div className="body1" style={{
                    ...isMobile  && {
                        fontSize: 12,
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }
                    }}>
                      NT${mainVariant.price} {productVariants.length > 1 && `起`}
                    </div>
                  )
                }
            </div>
        </motion.div>
    );
}

const Shop = ({
                  pageContext,
                  data
              }) => {

    const { isMobile } = useIsMobile();
    const {addItem} = useContext(CartContext);

    let products: Product[] = [];
    let tag;

    const query =
        typeof window !== 'undefined' ?
        queryString.parse(window.location.search) : {};

    products = data.shopify.products.edges.map((json) => {
        const r = Product.fromShopify(json);
        return r;
    })
    .filter((product) => {
        if (
          product.productType === '首頁公告' || 
          product.productType === '置頂消息' || 
          product.productType === '咖啡訂閱'
        ) {
          return false;
        }
        if (!query.type) {
          return true;
        }
        return product.productType === query.type;
    })
    .filter((product) => {
        if (!query.search) {
          return true;
        }
        const tags = product.tags.join() + ',';
        return tags.includes(`-${query.search},`);
    });

    const tags = [...new Set(data.shopify.products.edges.reduce((acc, product) => [...product.node.tags, ...acc], []).filter((tag) => ['產地-', '焙度-', '風味-', '工具-'].some((t) => tag.includes(t))))];

    return (
        <div>
            <div style={{
                display: "flex",
                flexDirection: isMobile ? 'column' : "row",
                paddingTop: 24,
            }}>

                {
                    !isMobile  &&

                        <SideBar key="shop-sidebar" tags={tags} />
                }
                <div style={{
                    flexGrow: 1,
                    display: "flex",
                    flexWrap: "wrap",
                    padding: `0 24px 24px 24px`,
                    flexDirection: "column",
                }}>
                    {

                        tag && <div style={{fontWeight: 'bold', fontSize: 24}}>
                            {tag.split('-')[1]}
                        </div>
                    }
                    {
                        products.length === 0 && <div style={{
                            margin: '24px 0',
                        }}>
                            目前沒有相關商品
                        </div>
                    }
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap', rowGap: 8}}>
                      {products.map(product => (
                            <ProductItem product={product} key={product.id} addItem={addItem} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Shop;
export const Head = ({ pageContext: { tag }}) => (
  <SEO
    title={tag || "商店"}
  />
)
